// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { parseISO, subDays } from "date-fns"
import styled, { css } from "styled-components"

import {
  Loading,
  Separator,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { parseErrorMessage } from "api/errors"

import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import { EmptyStateCardSection } from "components/generic/empty-state-card-section"

import { RestrictedLeaveDate } from "types/leave"

import { formatAsAbbreviatedDate, getDefaultTimezone } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"

import RestrictedLeaveDateModal from "./restricted-leave-date-modal"

interface RestrictedDateRowProps {
  restrictedDate: RestrictedLeaveDate
  audience: "driver" | "admin"
  editItem: () => void
}

const RestrictedDateRow = ({
  restrictedDate,
  audience,
  editItem,
}: RestrictedDateRowProps) => {
  const formatOffDays = (start: string, end: string) => {
    const firstDay = parseISO(start)
    const lastDay = subDays(parseISO(end), 1)
    if (firstDay == lastDay) {
      return formatAsAbbreviatedDate(firstDay, getDefaultTimezone())
    }
    return `${formatAsAbbreviatedDate(
      firstDay,
      getDefaultTimezone()
    )} - ${formatAsAbbreviatedDate(lastDay, getDefaultTimezone())}`
  }

  return (
    <>
      <Text>{restrictedDate.name}</Text>
      <Text>{formatOffDays(restrictedDate.start, restrictedDate.end)}</Text>
      <Text>
        {formatAsAbbreviatedDate(
          restrictedDate.snoozeRequestsUntil,
          getDefaultTimezone()
        )}
      </Text>
      {
        <Stack justify="end">
          <TextLink type="secondary" onClick={editItem}>
            {audience == "admin" ? "Edit" : "View"}
          </TextLink>
        </Stack>
      }
    </>
  )
}

interface RestrictedDatesListWrapperProps {
  audience: RestrictedDatesListProps["audience"]
}

const RestrictedDatesListWrapper = styled.div<RestrictedDatesListWrapperProps>`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 190px);
  display: grid;
  grid-template-columns:
    repeat(3, minmax(min-content, max-content))
    auto;
  column-gap: ${(props) => props.theme.orbit.spaceXXSmall};
  row-gap: ${(props) => props.theme.orbit.spaceMedium};
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  background: ${(props) => props.theme.orbit.backgroundCard};
  padding: ${(props) => props.theme.orbit.spaceSmall};
  ${media.mediumMobile(css`
    column-gap: ${(props) => props.theme.orbit.spaceSmall};
  `)}
  ${media.largeMobile(css`
    border: 1px solid ${(props) => props.theme.orbit.borderColorCard};
    padding: ${(props) => props.theme.orbit.spaceLarge};
    row-gap: ${(props) => props.theme.orbit.spaceLarge};
    column-gap: ${(props) => props.theme.orbit.spaceLarge};
  `)}
`

const SeparatorWrapper = styled.div`
  grid-column: 1 / -1;
  height: 1px;
  margin: 0px -${(props) => props.theme.orbit.spaceMedium};
  ${media.largeMobile(
    css`
      margin: 0px -${(props) => props.theme.orbit.spaceLarge};
    `
  )}
`

interface RestrictedDatesListProps {
  items: RestrictedLeaveDate[]
  audience: "driver" | "admin"
  openEditModal: (restrictedDate: RestrictedLeaveDate) => void
}

const RestrictedDatesList = ({
  items,
  audience,
  openEditModal,
}: RestrictedDatesListProps) => (
  <RestrictedDatesListWrapper audience={audience}>
    <>
      <Text weight="bold">Name</Text>
      <Text weight="bold">Restricted Dates</Text>
      <Text weight="bold">Snooze Requests Until</Text>
      <div />
      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>
    </>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        <RestrictedDateRow
          restrictedDate={item}
          audience={audience}
          editItem={() => openEditModal(item)}
        />
        {index < items.length - 1 && (
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
        )}
      </React.Fragment>
    ))}
  </RestrictedDatesListWrapper>
)

interface RestrictedLeaveDatesSectionProps {
  audience: "driver" | "admin"
  refreshCounter?: number
  refreshFunc: () => void
}

const RestrictedLeaveDatesSection = ({
  audience,
  refreshCounter,
  refreshFunc,
}: RestrictedLeaveDatesSectionProps) => {
  const [restrictedDates, setRestrictedDates] = useState<RestrictedLeaveDate[]>(
    []
  )
  const [_, setError] = useState(null)
  const [ladingTimeOffBookings, setLoadingRestrictedDates] = useState(false)
  const [showRestrictedDateModal, setShowRestrictedDateModal] = useState(false)
  const [editRestrictedDate, setEditRestrictedDate] =
    useState<RestrictedLeaveDate>()

  useEffect(() => {
    setLoadingRestrictedDates(true)
    setRestrictedDates([])
    fetchFromAPIBase({
      path: "/v1/staff/time-off/restricted-dates/",
    }).subscribe((response) => {
      if (response && !response.error) {
        response as RestrictedLeaveDate[]
        response.sort((a, b) => {
          if (a.snoozeRequestsUntil > b.snoozeRequestsUntil) return -1
          if (a.snoozeRequestsUntil < b.snoozeRequestsUntil) return 1
          return 0
        })
        setRestrictedDates(response)
      } else {
        setError(parseErrorMessage(response))
      }
      setLoadingRestrictedDates(false)
    })
  }, [refreshCounter])

  const openEditModal = (restrictedDate: RestrictedLeaveDate) => {
    setEditRestrictedDate(restrictedDate)
    setShowRestrictedDateModal(true)
  }

  const closeModal = () => {
    setShowRestrictedDateModal(false)
    setEditRestrictedDate(null)
  }

  return (
    <>
      {showRestrictedDateModal && (
        <RestrictedLeaveDateModal
          handleClose={closeModal}
          loadRestrictedDates={refreshFunc}
          editRestrictedDate={editRestrictedDate}
        />
      )}
      <EmberCardGroup
        sectionTitle="Restricted Dates"
        actions={[
          audience == "admin" && (
            <TextLink
              key="Add Restricted Date"
              type="secondary"
              onClick={() => setShowRestrictedDateModal(true)}
            >
              Add Restricted Date
            </TextLink>
          ),
        ]}
      >
        <EmberCard>
          {ladingTimeOffBookings ? (
            <EmberCardSection>
              <Loading type="boxLoader" text="Loading restricted dates" />
            </EmberCardSection>
          ) : restrictedDates.length > 0 ? (
            <RestrictedDatesList
              items={restrictedDates}
              audience={audience}
              openEditModal={openEditModal}
            />
          ) : (
            <EmptyStateCardSection
              title="No restricted dates found."
              actionLabel={audience == "admin" && "Add Restricted Date"}
              actionOnclick={() => setShowRestrictedDateModal(true)}
            />
          )}
        </EmberCard>
      </EmberCardGroup>
    </>
  )
}

export { RestrictedLeaveDatesSection }
