import React from "react"

import { format, parseISO, subDays } from "date-fns"
import { Form, Formik } from "formik"

import { Alert, Button, Stack, Text } from "@kiwicom/orbit-components"
import { Alert as AlertIcon } from "@kiwicom/orbit-components/icons"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import {
  EmberDateInput,
  EmberDateRangeInput,
  EmberTextInput,
} from "components/generic/formik-inputs"

import { RestrictedLeaveDate } from "types/leave"

import { getDefaultTimezone } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"

interface CreateTimeOffFormProps {
  submissionSuccess: boolean
  isSubmitting: boolean
  error: string
}

const CreateRestrictedLeaveDateForm = ({
  submissionSuccess,
  isSubmitting,
  error,
}: CreateTimeOffFormProps) => {
  return submissionSuccess ? (
    <Alert type="success">Success</Alert>
  ) : (
    <Form>
      <Stack spacing="large">
        <Stack spacing="XXSmall">
          <EmberTextInput label="Name" name="name" />
          <Text type="secondary">
            The name has to work in the sentence: the ______ period.
          </Text>
          <Text type="secondary" italic={true}>
            e.g. the 2020 Christmas period
          </Text>
        </Stack>

        <EmberDateRangeInput
          label="Dates (inclusive)"
          name="dateRange"
          timezone={getDefaultTimezone()}
        />
        <EmberDateInput label="Snooze Requests Until" name="snoozeUntil" />
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          fullWidth={true}
          submit={true}
        >
          Submit
        </Button>
        {error && (
          <Alert type="critical" icon={<AlertIcon />}>
            <Text>{error}</Text>
          </Alert>
        )}
      </Stack>
    </Form>
  )
}

interface RestrictedLeaveDateModalProps {
  handleClose: () => void
  loadRestrictedDates: () => void
  editRestrictedDate?: RestrictedLeaveDate
}

const RestrictedLeaveDateModal = ({
  handleClose,
  loadRestrictedDates,
  editRestrictedDate,
}: RestrictedLeaveDateModalProps) => {
  return (
    <EmberModal onClose={handleClose}>
      <EmberModalHeader title="Restricted Leave Date" />
      <EmberModalSection>
        <Formik
          initialValues={{
            name: editRestrictedDate ? editRestrictedDate.name : "",
            dateRange: editRestrictedDate
              ? {
                  start: parseISO(editRestrictedDate.start),
                  end: subDays(parseISO(editRestrictedDate.end), 1),
                }
              : { start: new Date(), end: new Date() },
            snoozeUntil: editRestrictedDate
              ? parseISO(editRestrictedDate.snoozeRequestsUntil)
              : new Date(),
          }}
          enableReinitialize
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true)
            setStatus(null)
            const { name, dateRange, snoozeUntil } = values
            fetchFromAPIBase({
              path: `/v1/staff/time-off/restricted-dates/${
                editRestrictedDate ? editRestrictedDate.id + "/" : ""
              }`,
              method: editRestrictedDate ? "PUT" : "POST",
              body: {
                start: format(dateRange.start, "yyyy-MM-dd"),
                end: format(dateRange.end, "yyyy-MM-dd"),
                snooze_requests_until: format(snoozeUntil, "yyyy-MM-dd"),
                name,
              },
            }).subscribe((response) => {
              if (!response.error) {
                setStatus({
                  success: true,
                })
                loadRestrictedDates()
                handleClose()
              } else {
                setStatus({
                  error: true,
                  message: parseErrorMessage(response),
                })
              }
              setSubmitting(false)
            })
          }}
        >
          {(props) => {
            return (
              <CreateRestrictedLeaveDateForm
                submissionSuccess={props.status?.success}
                isSubmitting={props.isSubmitting}
                error={props.status?.error ? props.status.message : ""}
              />
            )
          }}
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

export default RestrictedLeaveDateModal
